<ion-grid>
  <ion-row>
    <ion-col>
      <aaa-text i18n size="footnote" color="text">Filter:</aaa-text>

      <div class="filter-options">
        <aaa-button
          i18n
          i18n-aria-label
          [variant]="openOnly ? 'secondary' : 'outline'"
          size="small"
          (click)="handleFilter(AAR_FILTER_TYPES.OPEN)"
          aria-label="Show open facilities"
          tabindex="434"
        >Open</aaa-button>

        <aaa-button
          i18n
          i18n-aria-label
          [variant]="openOnly ? 'outline' : 'secondary'"
          size="small"
          (click)="handleFilter(AAR_FILTER_TYPES.ALL)"
          aria-label="Show all facilities"
          tabindex="434"
        >All</aaa-button>
      </div>
    </ion-col>

    <ion-col>
      <aaa-text i18n size="footnote" color="text">Sort by:</aaa-text>
      <div class="filter-options">
        <aaa-button
          i18n-aria-label
          [variant]="sorting === AAR_SORTING_TYPES.DISTANCE ? 'secondary' : 'outline'"
          size="small"
          (click)="handleSorting(AAR_SORTING_TYPES.DISTANCE)"
          aria-label="Distance filter"
          tabindex="436"
        >
          <aaa-icon
            i18n-aria-label
            *ngIf="sorting === AAR_SORTING_TYPES.DISTANCE"
            [name]="orderIcon"
            size="small"
            aria-label="Sort distance"
          ></aaa-icon>
          <ng-container i18n>Distance</ng-container>
        </aaa-button>

        <aaa-button
          i18n-aria-label
          *ngIf="hasRatings"
          [variant]="sorting === AAR_SORTING_TYPES.RATING ? 'secondary' : 'outline'"
          size="small"
          (click)="handleSorting(AAR_SORTING_TYPES.RATING)"
          aria-label="Rating filter"
          tabindex="437"
        >
          <aaa-icon
            i18n-aria-label
            *ngIf="sorting === AAR_SORTING_TYPES.RATING"
            [name]="orderIcon"
            size="small"
            aria-label="Sort rating"></aaa-icon>
          <ng-container i18n>Rating</ng-container>
        </aaa-button>

      </div>
    </ion-col>
  </ion-row>
</ion-grid>
