import { AbstractIconComponent } from './abstract.icon'
import { Component, Input } from '@angular/core'

@Component({
  selector: 'icon-aaa-logo',
  templateUrl: './aaa-logo.icon.html',
  styleUrls: ['aaa-logo.icon.scss']
})
export class AaaLogoIconComponent extends AbstractIconComponent {
  @Input() primary = '#D81E05'
  @Input() tertiary = '#003893'
}
