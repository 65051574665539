import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core'
import { Option } from '../../ui/ui.types'
import { TaggingService } from '@aaa-mobile/drrweb-lib'
import events from '../../tagging/events'

@Component({
  selector: 'app-passengers',
  templateUrl: './passengers.component.html',
  styleUrls: ['./passengers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassengersComponent implements OnChanges {
  @Input() isOpen = true
  @Output() close: EventEmitter<void> = new EventEmitter()
  @Output() select: EventEmitter<Option> = new EventEmitter()

  passengerOptions = [
    { label: $localize`Zero`, value: '0' },
    { label: $localize`One`, value: '1' },
    { label: $localize`Two`, value: '2' },
    { label: $localize`Three or More`, value: '3 OR MORE' },
  ]

  constructor(private tagging: TaggingService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isOpen.currentValue && !changes.isOpen.previousValue) {
      this.tagging.setPageEvent(
        events.towTo.PASSENGERS_PAGE_PROMPT,
        events.towTo.PASSENGERS_PAGE_TYPE
      )
    }
  }

  selectOption(option: Option) {
    this.select.emit({ ...option })
  }
}
