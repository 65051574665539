<ion-card class="ion-no-margin ion-margin-vertical">
  <div class="ion-padding card-container">
    <ion-item lines="none" class="ion-no-padding">
      <app-location-address [address]="address"></app-location-address>
      <aaa-button variant="ghost" size="small" (click)="handleClearAddress()" [disabled]="loading">
        <aaa-icon name="close" slot="end" color="dark" size="small"></aaa-icon>
      </aaa-button>
    </ion-item>
    <aaa-button
      class="ion-margin-top"
      expand="block"
      i18n
      (click)="handleConfirm()"
      [disabled]="adjustRequired"
      [loading]="loading"
    >
      Confirm Location
    </aaa-button>
  </div>
</ion-card>
