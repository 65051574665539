<ion-row>
  <ion-col>
    <ion-searchbar
      id="location-searchbar"
      type="text"
      color="light"
      [ngClass]="{ 'use-shadow': useShadow }"
      [placeholder]="placeholder"
      [mode]="ionicMode"
      (ionInput)="searchbarInput($event)"
      (ionClear)="clearSearch()"
      (ionChange)="focus()"
      [value]="selectedAddress"
      #search
     >
    </ion-searchbar>
    <ion-list
      [ngClass]="{ 'has-back-button': hasBackButton }"
      [mode]="ionicMode"
      [inset]="true"
      *ngIf="showOptions">
      <ion-item [mode]="ionicMode" *ngIf="suggestedDestination">
        <app-shop-card
          [destination]="suggestedDestination"
          (onCardClick)="suggestedDestinationClicked()"
          [borderShadow]="false"
          size="reduced"
        ></app-shop-card>
      </ion-item>
      <ion-item
        [mode]="ionicMode"
        *ngFor="let item of filteredItems;"
        (click)="locationSelect(item)"
        button
        detail>
        <app-location-address
          [address]="item"
          [matcherHighlight]="searchTerms"
        />
      </ion-item>
      <ion-item [mode]="ionicMode" *ngIf="searchFailed">
        <ion-label>
          <ion-text color="danger" i18n>Sorry, suggestions could not be loaded.</ion-text>
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-col>
</ion-row>
