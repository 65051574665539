<aaa-modal
  i18n-title
  [isOpen]="isOpen"
  [initialBreakpoint]="1"
  [breakpoints]="[0, 1]"
  contentMode="fit"
  title="Let’s find you and your vehicle"
  (aaaBreakpointDidChange)="setCurrentBreakpoint($event)"
>
  <div class="modal-container">
    <section (click)="handleFindMyLocation()">
      <aaa-text i18n
        >Please, click Allow when the browser prompts for your current
        location.</aaa-text
      >

      <div *ngIf="browserImage">
        <figure [ngClass]="browserImage.cssClass">
          <img [src]="browserImage.image" i18n-alt alt="Service Location" />

          <div class="border-hand">
            <icon-hand></icon-hand>
          </div>
        </figure>
      </div>

      <aaa-text
        class="ion-margin-bottom ion-margin-top"
        size="body"
        color="subtle"
        i18n
      >It's the fast way to access your precise location.</aaa-text>
    </section>

    <footer>
      <aaa-button
        id="find-my-location"
        expand="block"
        size="large"
        (click)="handleFindMyLocation()"
        [loading]="isLoading"
        [disabled]="hasDeniedGpsAccess || isLoading"
        appCtaClickEvent="Find My Location"
        type="submit"
      >
        <aaa-icon name="current-location"></aaa-icon>
        <ng-container i18n>Find My Location</ng-container>
      </aaa-button>

      <aaa-button
        expand="block"
        variant="ghost"
        size="large"
        (click)="handleEnterYourAddress()"
      >
        <aaa-icon name="search-outline"></aaa-icon>
        <ng-container i18n>Enter Your Address</ng-container>
      </aaa-button>
    </footer>
  </div>
</aaa-modal>
