import { Component, ViewChild } from '@angular/core'
import { BreakdownLocationComponent } from '../../location/breakdown-location/breakdown-location.component'
import { combineLatest, Observable } from 'rxjs';
import { select } from '@ngrx/store';
import {
  selectAdjustLocation,
  selectAdjustLocationAddress,
  selectBreakdownCoordinates,
  selectLocationCard,
  selectLocationMapTemplate
} from '../../location/location.selectors';
import { LocationCard, LocationMapTemplate, MapState } from '../../location/location.types';
import { GoogleCoordinates } from '../../location/google-geocode/types';
import { LOCATION_TYPE, setBreakdownLocationRequest } from '../../location/location.actions';
import { LocationAddress } from '../components/breakdown-location.types';
import { map } from 'rxjs/operators';
import { CITY_LEVEL_ZOOM, DEFAULT_MAX_MAP_ZOOM, DEFAULT_MIN_ZOOM } from '../../location/map/map.component';
import { LocationSearchComponent } from '../components/location-search/location-search.component'

@Component({
  selector: 'app-breakdown-location-step',
  templateUrl: './breakdown-location-step.component.html',
  styleUrls: ['./breakdown-location-step.component.scss'],
})
export class BreakdownLocationStepComponent extends BreakdownLocationComponent {
  @ViewChild('locationSearch') locationSearch: LocationSearchComponent

  isLocationDetailsDisplay = false

  locationCard$: Observable<LocationCard> = this.store$.pipe(
    select(selectLocationCard),
  )

  locationMap$: Observable<LocationMapTemplate> = this.store$.pipe(
    select(selectLocationMapTemplate)
  )

  breakdownCoordinates$: Observable<GoogleCoordinates> = this.store$.pipe(
    select(selectBreakdownCoordinates),
  )

  selectAdjustLocationAddress$: Observable<LocationAddress> = this.store$.pipe(
    select(selectAdjustLocationAddress)
  )

  selectAdjustLocation$: Observable<boolean> = this.store$.pipe(
    select(selectAdjustLocation)
  )

  zoomLevel$ = combineLatest([
    this.isBreakdownLocationValid$,
    this.selectAdjustLocation$,
  ]).pipe(map(([isValid, isAdjust]) => {
    let zoom = DEFAULT_MIN_ZOOM
    if (isAdjust) {
      zoom = CITY_LEVEL_ZOOM
    } else if (isValid) {
      zoom = DEFAULT_MAX_MAP_ZOOM
    }
    return zoom
  }))

  handleLocationSelected(location: LocationAddress) {
    delete location.icon
    this.handleAddressSelected(location)
  }

  handleEnterYourAddressClick() {
    this.onEnterYourAddressClick()
    this.locationSearch.focus()
  }

  handleAdjustLocationClick() {
    this.adjustLocation()
    this.locationSearch.focus()
  }

  handleMapDrag(mapState: MapState) {
    this.store$.dispatch(
      setBreakdownLocationRequest({
        payload: {
          lat: Number(mapState.center.latitude),
          lng: Number(mapState.center.longitude),
        },
        meta: { locationType: LOCATION_TYPE.PIN_DROP },
      })
    )
  }

  handleLocationConfirm(displayDetails: boolean) {
    this.isLocationDetailsDisplay = displayDetails
  }

  handleNext() {
    this.isLocationDetailsDisplay = false
    this.checkBreakdownLocationDistance()
  }
}
