import { Inject, Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { AAAStore } from '../../../store/root-reducer'
import { setABTestParam } from '../../ui/ui.actions'
import { ABTestParams, AdobeTargetResponse } from '../../ui/ui.types'
import { ConfigService } from '../../config/config.service'
import { ALLOY_INSTANCE } from './alloy.factory'
import { from, Observable, of } from 'rxjs'
import { catchError, map, take } from 'rxjs/operators'
import { turnOffRedesign, turnOnRedesign } from '../../../shared/utils/cookies';

@Injectable({
  providedIn: 'root'
})
export class AdobeTargetService {
  constructor(
    @Inject(ALLOY_INSTANCE) private alloy,
    private store$: Store<AAAStore>,
    private configService: ConfigService
  ) { }

  getTargetProposition(abTestParams: ABTestParams[]): Observable<boolean> {
    return from(this.alloy('sendEvent', {
      decisionScopes: abTestParams,
      renderDecisions: true,
    })).pipe(
      take(1),
      map(response => {
        this.validateAndApplyProposition(response, abTestParams)
        return true
      }),
      catchError(error => {
        this.logMessage('Error fetching Target Proposition:', error)
        return of(true)
      })
    )
  }

  private validateAndApplyProposition(response: AdobeTargetResponse, abTestParams: ABTestParams[]) {
    if (!response.propositions?.length) {
      this.logMessage('No Propositions available for', ...abTestParams )
      return null
    }
    const prop = response.propositions.find(p => p.scope === abTestParams[0] )
    const abTestParam = Object.values(ABTestParams).find(param => param === prop.scope)
    const isAVariation = prop.scopeDetails.experience.id === '0'

    if (!prop.scopeDetails || !prop.scopeDetails.experience || !prop.scopeDetails.experience.id || !abTestParam) {
      this.logMessage('Proposition details are not available or invalid')
      return null
    }

    this.logMessage('Propositions available:', response)
    this.processRedesignFlag(abTestParam, isAVariation)

    if (abTestParam) {
      this.alloy('applyPropositions', {
        propositions: response.propositions
      }).then(() => {
        if (isAVariation) {
          this.store$.dispatch(setABTestParam({ payload: [abTestParam, false] }))
          this.logMessage('Proposition applied successfully A for', abTestParam)
        } else {
          this.store$.dispatch(setABTestParam({ payload: [abTestParam] }))
          this.logMessage('Proposition applied successfully B for', abTestParam)
        }
      })
    }
  }

  processRedesignFlag(abTestParam: ABTestParams, isAVariation: boolean) {
    if ((abTestParam === ABTestParams.REDESIGN)) {
      isAVariation ? turnOffRedesign() : turnOnRedesign()
    }
  }

  // Todo: Create a shared console.log
  logMessage(...message) {
    if(this.configService.getConfig().configTools){
      console.log('Adobe Target Service:', ...message)
    }
  }

}
