import { AdobeEventTypes } from '../../tagging/tagging.types';
import { Component, EventEmitter, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { AAAStore } from '../../../store/root-reducer'
import {
  LOCATION_TYPE,
  locationNext,
  resetBreakdownLocation,
  SET_BREAKDOWN_LOCATION,
  SET_LOCATION_CLUB,
  SET_SHOW_ADJUST_LOCATION,
  setBreakdownLocationRequest,
  setLandMark,
  setLastSearchLocation,
  setLocationDetails,
  setResetLocationDenied
} from '../location.actions'
import { combineLatest, Observable, timer } from 'rxjs'
import {
  selectBreakdownLocation,
  selectBreakdownLocationAddress,
  selectBreakdownLocationDetails,
  selectBreakdownMarker,
  selectCurrentGeoLocation,
  selectGpsLocationDenied,
  selectHasGPSAccess,
  selectHighwayExit,
  selectIsBreakdownLocationValid,
  selectLandMarkAndAddress,
  selectLastSearchLocation,
} from '../location.selectors';
import { MemberInfo } from '../../member/member.types'
import {
  BreakdownLocation,
  BreakdownLocationDetails,
  GenericCoordinates,
  GoogleLocationMarker,
  LastSearchLocation,
  SearchViews,
} from '../location.types'
import { map, withLatestFrom } from 'rxjs/operators'
import {
  selectFullMapBreakdownLocation,
  selectInitialMapLocation,
  selectUserDefaultCoords,
} from '../../ui/ui.selectors'
import { DEFAULT_LAT, DEFAULT_LNG, GPS_LOCATION_DISTANCE_WARN_LIMIT } from '../location.constants'
import { TaggingService } from '../../tagging/tagging.service'
import { selectIsLoading } from '../../ui/loading/loading.selectors'
import { selectMemberData } from '../../member/member.selectors'
import { selectAuthMethod, selectIsRapUser, selectIsSecure } from '../../auth/auth.selectors'
import { GoogleAddress, GoogleCoordinates } from '../google-geocode/types'
import { isAddressComplete, isHomeAddressComplete } from '../location.utils'
import { MessageDialogTypes, PromptDialogTypes } from '../../ui/ui.types'
import { closeDialog, openMessageDialog, openPromptDialog, setFullMapBreakdownLocation } from '../../ui/ui.actions'
import { addPartialCallRequest } from '../../dashboard/calls.actions'
import { SET_SERVICING_CLUB_CONFIGS } from '../../servicing-club/servicing-club.actions'
import { AbstractResponsiveComponent } from '../../../shared/abstract-responsive.component'
import events from '../../tagging/events'
import { PaceSetterCode } from '../../issue/issue.types'
import { selectActivePaceSetterCode } from '../../issue/issue.selectors'
import { haversine } from '../../../shared/haversine'
import { LocationAutocompleteComponent } from '../location-auto-complete/location-auto-complete.component'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service';

@Component({
  selector: 'app-select-location',
  templateUrl: './breakdown-location.component.html',
  styleUrls: ['./breakdown-location.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BreakdownLocationComponent
  extends AbstractResponsiveComponent
  implements OnInit {
  breakdownLocationLandMark$: Observable<string> = this.store$.pipe(
    select(selectLandMarkAndAddress)
  )
  isBreakdownLocationValid$: Observable<boolean> = this.store$.pipe(
    select(selectIsBreakdownLocationValid)
  )
  breakdownLocationAddress$: Observable<string> = this.store$.pipe(
    select(selectBreakdownLocationAddress)
  )
  breakdownLocation$: Observable<BreakdownLocation> = this.store$.pipe(
    select(selectBreakdownLocation)
  )
  breakdownMarker$: Observable<GoogleLocationMarker> = this.store$.pipe(
    select(selectBreakdownMarker),
  )
  isFullMapBreakdownLocation$: Observable<boolean> = this.store$.pipe(
    select(selectFullMapBreakdownLocation)
  )

  member$: Observable<MemberInfo> = this.store$.pipe(select(selectMemberData))

  isSecure$: Observable<boolean> = this.store$.pipe(select(selectIsSecure))

  isSetBreakdownLocationLoading$: Observable<boolean> = this.store$.pipe(
    select(selectIsLoading(SET_BREAKDOWN_LOCATION.ACTION))
  )

  details$: Observable<BreakdownLocationDetails> = this.store$.pipe(
    select(selectBreakdownLocationDetails)
  )

  userCoordsByParams$ = this.store$.pipe(select(selectUserDefaultCoords))
  highWayExit$ = this.store$.pipe(select(selectHighwayExit))

  userCoordsByGPS$: Observable<GenericCoordinates> = this.store$.pipe(
    select(selectCurrentGeoLocation)
  )

  lastSearchLocation$: Observable<LastSearchLocation> = this.store$.pipe(
    select(selectLastSearchLocation)
  )

  isRapUser$: Observable<boolean> = this.store$.pipe(select(selectIsRapUser))

  isFullMap
  isBreakdownLocationValid
  showFullMap$ = combineLatest([
    this.isFullMapBreakdownLocation$,
    this.isBreakdownLocationValid$,
    this.userCoordsByParams$,
    this.isSecure$,
  ]).pipe(map(([isFullMapBreakdownLocation, isBreakdownLocationValid, userCoordsByParams, isSecure]) => {
      this.isFullMap = isFullMapBreakdownLocation && !isBreakdownLocationValid && !isSecure && !userCoordsByParams?.lat && !userCoordsByParams?.lng
      this.isBreakdownLocationValid = isBreakdownLocationValid
      this.sendPageLoadEvent()
      return this.isFullMap
    }))

  gpsCoordinates
  userCoords$ = combineLatest([
    this.userCoordsByGPS$,
    this.userCoordsByParams$,
  ]).pipe(
    map(([userCoordsByGPS, userCoordsByParams]) => {
      this.gpsCoordinates = userCoordsByGPS
      const _coords = userCoordsByGPS || userCoordsByParams
      this.gpsCoordinates = _coords
      return _coords
    })
  )

  isServicingClubConfigLoading$ = combineLatest([
    this.store$.pipe(select(selectIsLoading(SET_LOCATION_CLUB.ACTION))),
    this.store$.pipe(
      select(selectIsLoading(SET_SERVICING_CLUB_CONFIGS.ACTION))
    ),
  ]).pipe(map((areActionsLoading) => areActionsLoading.find(Boolean)))

  $initialLatLng: Observable<GoogleAddress> = this.store$.pipe(
    select(selectInitialMapLocation),
    withLatestFrom(
      this.userCoordsByParams$,
    ),
    map(
      ([initialCoords, userCoords]: [
        GoogleAddress,
        GoogleAddress,
      ]) => userCoords || initialCoords
    )
  )

  activePaceSetterCode$: Observable<PaceSetterCode> = this.store$.pipe(select(selectActivePaceSetterCode))
  hasGPSAccess$: Observable<boolean> = this.store$.pipe(select(selectHasGPSAccess))
  hasDeniedGpsAccess$: Observable<boolean> = this.store$.pipe(select(selectGpsLocationDenied))

  SearchViewsType = SearchViews
  isShaking = false
  selectedAddress: string
  isValid = false
  isPristine = true
  isLoading = false
  hasCoordinates = false
  hasDetails = false
  breakdownLocation: BreakdownLocation = null
  initialCoords: GoogleCoordinates = {
    lat: DEFAULT_LAT,
    lng: DEFAULT_LNG,
  }
  @ViewChild('autocomplete')
  autocomplete: LocationAutocompleteComponent

  details: BreakdownLocationDetails = {
    options: [],
    notes: '',
  }

  isSoftReset: Boolean = false
  memberInfo: MemberInfo
  isHomeAddressComplete: Boolean = false
  paceSetterCode: PaceSetterCode
  hasGPSAccess: boolean

  showAdjustLocation: Boolean = false
  showAdjustLocationNeedMoreInfo$: EventEmitter<Boolean> = new EventEmitter<Boolean>()
  inputAddressFullscreen: Boolean = false

  authMethod$ = this.store$.pipe(select(selectAuthMethod))

  constructor(
    private actions$: Actions,
    protected store$: Store<AAAStore>,
    private tagging: TaggingService,
    private adobeEventService: AdobeEventService,
  ) {
    super()
  }

  setShowAdjustLocation$ = createEffect(() => this.actions$.pipe(ofType(SET_SHOW_ADJUST_LOCATION)), { dispatch: false });

  ngOnInit() {
    this.store$.dispatch(setResetLocationDenied())
    this.isPristine = true
    this.subscriptions.push(
      this.breakdownLocationLandMark$.subscribe((address) => {
        this.selectedAddress = this.isSoftReset ? this.selectedAddress : address
        this.isValid = address && address !== ''
        this.isSoftReset = false
      }),
      this.breakdownLocationAddress$.subscribe((address) => {
        this.selectedAddress = this.isSoftReset ? this.selectedAddress : address
        this.isValid = address && address !== ''
        this.isSoftReset = false
      }),
      this.breakdownLocation$.subscribe((location) => {
        this.breakdownLocation = location
        this.hasCoordinates = !!(
          location &&
          location.latitude &&
          location.longitude
        )
      }),
      this.member$.subscribe((member: MemberInfo) => {
        this.memberInfo = member
        this.isHomeAddressComplete = isHomeAddressComplete(member)
      }),
      this.store$
        .pipe(select(selectBreakdownLocationDetails))
        .subscribe((details) => {
          this.details = details
          this.hasDetails =
            details &&
            ((details.notes && details.notes.length > 0) ||
              details.options.length > 0)
        }),
      this.$initialLatLng.subscribe((location: GoogleAddress) => {
        if (!location.lat && !location.lng) {
          return
        }

        this.initialCoords = { ...location }
      }),
      this.hasGPSAccess$.subscribe((hasGPSAccess) => {
        this.hasGPSAccess = hasGPSAccess
        if (hasGPSAccess) {
          this.store$.dispatch(
            closeDialog({
              payload: { type: MessageDialogTypes.LOCATION_SERVICES_REQUIRED },
            })
          )
        }
      }),
      this.isSetBreakdownLocationLoading$.subscribe((isLoading) => {
        this.isLoading = isLoading
      }),
      this.activePaceSetterCode$.subscribe((paceSetterCode) => this.paceSetterCode = paceSetterCode),
      this.setShowAdjustLocation$.subscribe(({payload: {isAddressComplete: _isAddressComplete}}) => {
        this.showAdjustLocation = !_isAddressComplete
        this.showAdjustLocationNeedMoreInfo$.emit(!_isAddressComplete)
      })
    )
  }

  handleIsValidChange(isValid) {
    this.isValid = isValid === 'reset' ? false : isValid
    if (!isValid) {
      this.isSoftReset = isValid !== 'reset'
      this.store$.dispatch(resetBreakdownLocation())
    }
  }

  help() {
    this.store$.dispatch(openMessageDialog({
      payload: {
        type: MessageDialogTypes.LOCATION_SERVICES_REQUIRED,
      },
    }))
  }

  addressCleared = false
  handleAddressSelected(address) {
    this.addressCleared = !Object.keys(address).length
    this.store$.dispatch(resetBreakdownLocation())
    if (this.addressCleared) {
      return
    }

    if (address.description) {
      this.inputAddressFullscreen = false
      this.store$.dispatch(
        setBreakdownLocationRequest({
          payload: address.description,
          meta: { locationType: LOCATION_TYPE.ADDRESS_INPUT },
        })
      )
      this.adobeEventService.sendEvent({
        eventValue: events.location.LOCATION_SEARCH_CLICK,
        eventName: AdobeEventTypes.CTA
      })

      this.tagging.setClickEvent(
        events.location.LOCATION_SEARCH_CLICK,
        events.location.LOCATION_PAGE_TYPE
      )
    }

    if (address?.landmark) {
      this.store$.dispatch(
        setLandMark({
          payload: address?.landmark || '',
        })
      )
    }

    if (address?.main_text) {
      this.store$.dispatch(setLastSearchLocation({ payload: address }))
    }
  }

  handleOptionalDetailsChanged(event) {
    const selected = event.options.length
    const options = (selected ? event.options : this.details.options || []).map((opt) => opt.name)?.join(', ')
    // not expected to have no option been selected or deselecting when none is selected, anyways just in case
    if (!options) {
      return
    }
    const _action = `${events.location.LOCATION_DETAILS_SELECT}: ${options}${selected ? '' : ' UNSELECTED'}`

    this.details = { ...event }
    this.storeOptionalDetails()

    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: _action
    })
    this.tagging.setClickEvent(_action, events.location.LOCATION_PAGE_TYPE)
  }

  useHomeLocation(member: MemberInfo) {
    this.store$.dispatch(resetBreakdownLocation())
    this.store$.dispatch(
      setBreakdownLocationRequest({
        payload: { member },
        meta: { locationType: LOCATION_TYPE.HOME },
      })
    )

    this.tagging.setClickEvent(
      events.location.LOCATION_HOME_CLICK,
      events.location.LOCATION_PAGE_TYPE
    )
  }

  useCurrentLocation({ isFindMyLocationClick = false } = {}) {
    this.inputAddressFullscreen = false
    this.store$.dispatch(resetBreakdownLocation())
    this.store$.dispatch(setFullMapBreakdownLocation({ payload: false }))
    this.store$.dispatch(
      setBreakdownLocationRequest({
        payload: this.gpsCoordinates,
        meta: { locationType: LOCATION_TYPE.GPS_LOCATION },
      })
    )

    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: isFindMyLocationClick
        ? events.location.LOCATION_FIND_MY_LOCATION
        : events.location.LOCATION_CURRENT_CLICK
    })

    this.tagging.setClickEvent(
      events.location.LOCATION_CURRENT_CLICK,
      events.location.LOCATION_PAGE_TYPE
    )

    this.checkShakeLocationServicesBanner()
  }

  checkBreakdownLocationDistance() {
    if (
      this.gpsCoordinates &&
      (
        this.breakdownLocation?.locationType === LOCATION_TYPE.PIN_DROP ||
        this.breakdownLocation?.locationType === LOCATION_TYPE.ADDRESS_INPUT
      )
    ) {
      const gpsToLocationDistance = haversine({
          latitude: Number(this.breakdownLocation.latitude),
          longitude: Number(this.breakdownLocation.longitude),
        },
        {
          latitude: Number(this.gpsCoordinates.lat),
          longitude: Number(this.gpsCoordinates.lng),
        },
        { unit: 'mile' })

      if (gpsToLocationDistance > GPS_LOCATION_DISTANCE_WARN_LIMIT) {
        return this.store$.dispatch(openMessageDialog({
          payload: {
            type: MessageDialogTypes.GPS_LOCATION_DISTANCE_WARNING,
            disableClose: true,
            submit: () => this.onNext()
          },
        }))
      }
    }

    this.onNext()
  }

  onNext() {
    this.isPristine = false

    if (this.isValid && this.hasCoordinates) {
      this.adobeEventService.sendEvent({
        eventName: AdobeEventTypes.CTA,
        eventValue: events.location.LOCATION_NEXT_CLICK,
      })
      this.tagging.setClickEvent(
        events.location.LOCATION_NEXT_CLICK,
        events.location.LOCATION_PAGE_TYPE
      )
      this.storeOptionalDetails()

      if (!isAddressComplete(this.breakdownLocation) && !this.hasDetails) {
        return this.store$.dispatch(
          openPromptDialog({
            payload: {
              type: PromptDialogTypes.ADDITIONAL_LOCATION_INFO,
            },
          })
        )
      } else {
        this.store$.dispatch(locationNext())
        this.store$.dispatch(addPartialCallRequest())
      }
    } else {
      this.autocomplete.focus()
    }
  }

  storeOptionalDetails() {
    this.store$.dispatch(
      setLocationDetails({
        payload: { ...this.details },
      })
    )
  }

  adjustLocation() {
    this.showAdjustLocation = true
    this.showAdjustLocationNeedMoreInfo$.emit(false)
    this.tagging.setPageLoadEvent({ pageType: events.location.PAGE_TYPE, pageName: events.location.PAGE_NAME_ADJUST_LOCATION })
  }

  onEnterYourAddressClick() {
    this.store$.dispatch(setFullMapBreakdownLocation({payload: false}))
  }

  closeAdjustLocation(shouldResetAddress = false) {
    this.showAdjustLocation = false
    this.showAdjustLocationNeedMoreInfo$.emit(false)
    this.inputAddressFullscreen = shouldResetAddress
    this.sendPageLoadEvent()
  }

  checkShakeLocationServicesBanner() {
    this.isShaking = true
    const SHAKE_TIMEOUT = 1500

    const shakeTimer = timer(SHAKE_TIMEOUT).subscribe(() => {
      this.isShaking = false
    })
    this.subscriptions.push(shakeTimer)
  }

  autoCompleteBackButton() {
    this.sendPageLoadEvent()
  }

  sendPageLoadEvent() {
    let pageName = this.isFullMap ? events.location.PAGE_NAME_GPS_INSTRUCTION : events.location.PAGE_NAME_LOCATION_DEFAULT
    if (!this.isFullMap && this.isBreakdownLocationValid) {
      pageName = events.location.PAGE_NAME_CONFIRM_ADDRESS
    }
    if (!this.addressCleared || !this.isMobile) {
      this.tagging.setPageLoadEvent({ pageType: events.location.PAGE_TYPE, pageName })
    }
    this.addressCleared = false
  }
}
