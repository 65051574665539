<ion-grid class="ion-no-padding">
  <ion-row class="ion-margin">
    <ion-col>
      <aaa-text weight="semibold" color="dark" size="title3" i18n>Issue Selection</aaa-text>
      <aaa-text color="dark" size="subheadline" i18n>How can we help you?</aaa-text>
    </ion-col>
  </ion-row>
  <ion-row>
    <ng-container>
      <app-issue-tooltip (selectIssue)="problemSelected($event)"></app-issue-tooltip>
      <app-issue-selection (selectIssue)="problemSelected($event)"></app-issue-selection>
      <ng-container *ngIf=" (activeSituationName$ | async) as activeSituationName">
          <form #f="ngForm" id="select-issue-form" (ngSubmit)="validate(f) && next($event, activeSituationName)" novalidate>
            <app-situation-editor-v2
              [containingForm]="f"
              [template]="currentTemplate$ | async"
              [isOpen]="isIssueSelected"
              (close)="handleCloseSituation()"
              (onNext)="validate(f) && next($event, activeSituationName)"
            ></app-situation-editor-v2>
          </form>
      </ng-container>

    </ng-container>
  </ion-row>
</ion-grid>
