<div *ngIf="problems">
  <div *ngIf="heading">
    <span>{{ heading }}</span>
  </div>
  <div>
    <ion-list>
      <ion-item *ngFor="let problem of problems; let i = index">
        <aaa-checkbox
          [ngModel]="isSelected(problem)"
          (aaaChange)="problemChange(problem, $event)"
        >
          <aaa-text color="dark">
            {{ problem.label || problem.name }}
            <div class="problem-checklist_obs" *ngIf="problem.obs">
              {{ problem.obs }}
            </div>
          </aaa-text>
        </aaa-checkbox>
      </ion-item>
    </ion-list>
  </div>
</div>
