<ion-card *ngIf="problemType"
          appCtaClickEvent="Issue Selected"
          [issueType]="problemType.name"
          class="ion-no-margin"
          [ngClass]="{ 'first-focusable-element': first, 'disabled': !overrideProblem.enabled }"
          (click)="problemClick($event)"
          [title]="problemType.label | uppercase"
          [attr.aria-title]="problemType.label"
          [tabindex]="tabIndex">
  <aaa-icon class="info-icon" name="information-circle-outline" size="medium"
            appCtaClickEvent="Tooltip Displayed"
            [issueType]="problemType.name"
            [ngClass]="{'info-icon_active': (problemTooltip$ | async)?.name === problemType.name }"
            (click)="showProblemTooltip($event)"
            [attr.aria-expanded]="problemTooltip$ | async"></aaa-icon>
  <aaa-icon class="issue-icon" [name]="problemType.iconName" size="x-large"
            basePath="assets/drrweb-lib/icons/"></aaa-icon>
  <aaa-text size="subheadline" weight="medium" color="subtle" align="left">
    {{ problemType.label | titlecase }}
  </aaa-text>
</ion-card>
