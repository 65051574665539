 <ion-grid>
  <ion-row class="ion-align-items-center">
    <ion-col size="auto" class="padding-4">
      <aaa-icon [name]="getIcon()" [size]="iconSize" [color]="iconColor" basePath="assets/drrweb-lib/icons/"></aaa-icon>
    </ion-col>
    <ion-col class="padding-4">
      <ion-row class="address-line-1" *ngIf="address?.main_text">
        <ion-column>
          <ion-label [innerHTML]="address.main_text | matcherHighlight:matcherHighlight"/>
        </ion-column>
      </ion-row>
      <ion-row class="address-line-2" *ngIf="address?.secondary_text">
        <ion-column>
          <ion-label [innerHTML]="address.secondary_text | matcherHighlight:matcherHighlight"/>
        </ion-column>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-grid>
