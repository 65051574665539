import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { PaceSetterDetails, PaceSetterList, PaceSetterOptions, PaceSetterSituation, } from '../issue.types'
import { Observable } from 'rxjs'
import { select, Store } from '@ngrx/store'
import {
  selectActivePaceSetterDetails,
  selectActivePaceSetterSelection,
  selectActivePaceSetterSituation,
  selectActivePaceSetterStep,
} from '../issue.selectors'
import { AAAStore } from '../../../store/root-reducer'
import { AbstractResponsiveComponent } from '../../../shared/abstract-responsive.component'
import { selectIsRapUser } from '../../auth/auth.selectors';
import { Location } from '@angular/common'
import { TaggingService } from '../../tagging/tagging.service';

@Component({
  selector: 'app-situation-editor-v2',
  templateUrl: './situation-editor-v2.component.html',
  styleUrls: ['./situation-editor-v2.component.scss'],
})
export class SituationEditorV2Component
  extends AbstractResponsiveComponent
  implements OnInit {
  details$: Observable<PaceSetterDetails> = this.store$.pipe(
    select(selectActivePaceSetterDetails)
  )
  isRapUser$: Observable<boolean> = this.store$.pipe(
    select(selectIsRapUser)
  )

  activeStep$: Observable<number> = this.store$.pipe(
    select(selectActivePaceSetterStep)
  )

  activeSelections$: Observable<PaceSetterList[]> = this.store$.pipe(
    select(selectActivePaceSetterSelection)
  )

  activeSituation$: Observable<PaceSetterSituation> = this.store$.pipe(
    select(selectActivePaceSetterSituation)
  )

  @Input() template: PaceSetterOptions
  @Input() containingForm: any
  @Input() isOpen = false
  @Output() close: EventEmitter<void> = new EventEmitter()
  @Output() onNext: EventEmitter<void> = new EventEmitter()

  listItemsCount: number

  constructor(
    private store$: Store<AAAStore>,
    private location: Location,
    private taggingService: TaggingService,
  ) {
    super()
  }

  handleNext(event) {
    this.onNext.emit(event)
  }

  ngOnInit() {
    const pageLoadTagging = this.template?.pageLoadTagging
    if (pageLoadTagging) {
      this.taggingService.setPageLoadEvent({ pageType: pageLoadTagging.pageType, pageName: pageLoadTagging.pageName })
    }
    this.containingForm?.form.markAsDirty()
    this.activeStep$.subscribe((activeStep) => {
      const detailGroup = this.template.detailGroups[activeStep - 1]
      // The count of list items (if any) is in use for incrementing the UI tab index
      this.listItemsCount =
        detailGroup && detailGroup.lists
          ? this.template.detailGroups[activeStep - 1].lists[0]?.values.length
          : 1

      if(!this.listItemsCount) {
        this.listItemsCount = 0
      }
    })
  }

}
