import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { formatAddress, GoogleLocationMarker } from '@aaa-mobile/drrweb-lib'
import { LocationAddress } from '../../breakdown-location/components/breakdown-location.types'

@Component({
  selector: 'app-custom-destination-card',
  templateUrl: './custom-destination-card.component.html',
  styleUrls: ['./custom-destination-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomDestinationCardComponent implements OnInit {
  @Input() location: GoogleLocationMarker
  @Input() loading: boolean

  @Output() onUseThisLocation = new EventEmitter<GoogleLocationMarker>()
  @Output() onClearLocation = new EventEmitter<void>();

  address: LocationAddress

  ngOnInit(): void {
    this.address = formatAddress(this.location.address)
    this.address.icon = 'drr-place-pin'
  }

  handleUseThisLocation() {
    this.onUseThisLocation.emit(this.location)
  }

  handleClearLocation() {
    this.address = null
    this.onClearLocation.emit()
  }

}
