import { MapModalComponent } from './map-modal/map-modal.component'
import { LocationComponent } from './location.component'
import { BreakdownLocationDetailsComponent } from './breakdown-location-details/breakdown-location-details.component'
import { BreakdownLocationComponent } from './breakdown-location/breakdown-location.component'
import { LocationAutocompleteComponent } from './location-auto-complete/location-auto-complete.component'
import { TowLocationPromoComponent } from './tow-location-promo/tow-location-promo.component'
import { TowLocationSummaryComponent } from './tow-location-summary/tow-location-summary.component'
import { TowLocationListItemComponent } from './tow-location-list-item/tow-location-list-item.component'
import { TowLocationFiltersComponent } from './tow-location-filters/tow-location-filters.component'
import { IndicatePassengersComponent } from './tow-location/indicate-passengers/indicate-passengers.component'
import {
  BreakdownLocationFullMapComponent
} from './breakdown-location-full-map/location-full-map/breakdown-location-full-map.component'
import { TowLocationComponent } from './tow-location/tow-location.component'
import {
  TowLocationListSkeletonComponent
} from './tow-location-list-item/tow-location-skeleton-item/tow-location-skeleton-item.component'
import {
  BreakdownLocationStepComponent
} from '../breakdown-location/breakdown-location-step/breakdown-location-step.component';
import { AdjustLocationCardComponent } from '../breakdown-location/adjust-location-card/adjust-location-card.component'
import { TowLocationStepComponent } from '../tow-location/tow-location-step/tow-location-step.component';
import { ShopPreferencesComponent } from './shop-preferences/shop-preferences.component'
import { PassengersComponent } from './passengers/passengers.component'

export function locationComponents() {
  return [
    MapModalComponent,
    LocationComponent,
    BreakdownLocationComponent,
    BreakdownLocationStepComponent,
    BreakdownLocationDetailsComponent,
    LocationAutocompleteComponent,
    TowLocationComponent,
    TowLocationStepComponent,
    TowLocationPromoComponent,
    TowLocationSummaryComponent,
    TowLocationListItemComponent,
    TowLocationFiltersComponent,
    IndicatePassengersComponent,
    BreakdownLocationFullMapComponent,
    TowLocationListSkeletonComponent,
    AdjustLocationCardComponent,
    ShopPreferencesComponent,
    PassengersComponent,
  ]
}
