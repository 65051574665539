import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { GooglePlacesService } from '../../../ui/autocomplete/google-places/google-places.service'
import { GenericCoordinates, LastSearchLocation, USE_CURRENT_LOCATION } from '../../../location/location.types'
import { LocationAddress } from '../breakdown-location.types'
import { IonSearchbar } from '@ionic/angular'
import { AARData } from '../../../location/aar/aar.types'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators'
import { GoogleMapsConfig } from '../../../../google-maps-config'


@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.scss'],
  providers: [GooglePlacesService],
})
export class LocationSearchComponent implements OnInit {
  @ViewChild('search') search: IonSearchbar

  @Input()
  location: GenericCoordinates
  @Input()
  selectedAddress: string
  @Input()
  gpsAvailable = false
  @Input()
  minLengthToFetch = 3
  @Input()
  showUseCurrentLocation = false
  @Input()
  lastSearchLocation: LastSearchLocation | undefined
  @Input()
  suggestedDestination: AARData | undefined
  @Input()
  placeholder: string | undefined
  @Input()
  useShadow = true
  @Input()
  hasBackButton = false

  @Output()
  locationSelected = new EventEmitter<LocationAddress>()
  @Output()
  suggestedDestinationClick = new EventEmitter<number>()

  @Output()
  useCurrentLocation = new EventEmitter<void>()

  ionicMode = 'ios'

  showOptions = false
  searchFailed = false
  searchTerms: string
  searchTermsSubject = new Subject<string>()

  filteredItems: any = []

  constructor(
    private googlePlacesService: GooglePlacesService,
    private googleMapsConfig: GoogleMapsConfig
  ) {}

  ngOnInit() {
    this.googleMapsConfig.obsCurrentApiStatus.pipe(
      filter(status => status === true),
      tap(() => this.setupSearch())
    ).subscribe();
  }

  focus() {
    setTimeout(() => {
      this.search.setFocus()
    }, 1000)
  }

  locationSelect(item: LocationAddress) {
    if (item.custom_value === USE_CURRENT_LOCATION) {
      if (this.gpsAvailable) {
        this.useCurrentLocation.emit()
        this.showOptions = false
      }
    } else {
      this.locationSelected.emit(item)
      this.showOptions = false
    }
  }

  suggestedDestinationClicked() {
    this.suggestedDestinationClick.emit(this.suggestedDestination.id)
  }

  clearSearch() {
    this.searchFailed = false
    this.searchTerms = ''
  }

  private setupSearch() {
    this.searchTermsSubject.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term => {
        this.searchTerms = term
        return this.googlePlacesService.search(term, this.location).toPromise()
      })
    ).subscribe(
      data => {
        this.assembleItems(data)
      },
      error => {
        this.searchFailed = true
        this.filteredItems = []
      }
    )
  }

  searchbarInput(input: CustomEvent) {
    const value = input.detail.value
    this.searchFailed = false
    if (value.length >= this.minLengthToFetch) {
      this.searchTermsSubject.next(value)
      this.showOptions = true
    } else {
      this.showOptions = false
    }
  }

  assembleItems(items) {
    const customItems = []
    if (this.showUseCurrentLocation) {
      customItems.push({
        icon: this.gpsAvailable ? 'location' : 'location-off',
        main_text: $localize`Use current position`,
        custom_value: USE_CURRENT_LOCATION,
      })
    }
    if (this.lastSearchLocation) {
      customItems.push({
        ...this.lastSearchLocation,
        icon: 'clock',
      })
    }
    this.filteredItems = [...customItems, ...items]
  }
}
