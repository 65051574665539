<h1 i18n class="condensed">How many people need a ride in the tow truck?</h1>
<div class="body">
  <form id='submit-passengers' (ngSubmit)="onNext()" novalidate [ngrxFormState]="passengersForm">
    <div class="indicate-passengers_how-many">
      <app-option-selector
        [values]="_passengerOptions"
        [ngrxFormControlState]="controls?.quantity"
        (modelChange)="updatePassengers($event)"
        [tabIndex]="500"
      >
      </app-option-selector>
      <hr class="light-divider" />
    </div>

    <app-button
      i18n
      type="submit"
      class="indicate-passengers_next"
      [inverted]="true"
      [disabled]="!passengersForm?.isValid"
      classNames="base-btn-size"
      [tabIndex]="515">Next</app-button>
  </form>
</div>

