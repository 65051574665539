import { WhatHappenedComponent } from './what-happened/what-happened.component'
import { ProblemButtonComponent } from './problem-button/problem-button.component'
import { ProblemChecklistComponent } from './problem-checklist/problem-checklist.component'
import { SituationDetailsComponent } from './situation-details/situation-details.component'
import { SituationEditorComponent } from './situation-editor/situation-editor.component'
import { SituationSummaryComponent } from './situation-summary/situation-summary.component'
import { IssueComponent } from './issue.component'

import { DescriptionNoteComponent } from './description-note/description-note.component'
// import { AccidentComponent } from './accident/accident.component'
import { ProblemTooltipComponent } from './problem-tooltip/problem-tooltip.component'
import { IssueSelectionComponent } from './issue-selection/issue-selection.component'
import { IssueStepComponent } from './issue-step/issue-step.component';
import { IssueButtonComponent } from './issue-button/issue-button.component';
import { IssueTooltipComponent } from './issue-tooltip/issue-tooltip.component';
import { SituationEditorV2Component } from './situation-editor-v2/situation-editor-v2.component'
import { SituationDetailsV2Component } from './situation-details-v2/situation-details-v2.component'
import { ProblemChecklistV2Component } from './problem-checklist-v2/problem-checklist-v2.component'

export function issueComponents() {
  return [
    WhatHappenedComponent,
    ProblemButtonComponent,
    IssueButtonComponent,
    ProblemChecklistComponent,
    SituationDetailsComponent,
    SituationEditorComponent,
    SituationSummaryComponent,
    IssueComponent,
    IssueStepComponent,
    DescriptionNoteComponent,
    // AccidentComponent,
    ProblemTooltipComponent,
    IssueTooltipComponent,
    IssueSelectionComponent,
    SituationEditorV2Component,
    SituationDetailsV2Component,
    ProblemChecklistV2Component,
  ]
}
