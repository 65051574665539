<ng-container *ngIf="destination.evStationDetails?.chargeLevel as chargerLevel; else shop">
  <app-charging-level-list-v2
    [dcFast]="chargerLevel.dcFast"
    [level1]="chargerLevel.level1"
    [level2]="chargerLevel.level2"
  ></app-charging-level-list-v2>
</ng-container>
<ng-template #shop>
  <ion-grid class="ion-no-padding">
    <ion-row class="info-section">
      <ion-col class="icon-column" size="auto">
        <ng-container *ngIf="isOwnedShop(); else approvedShop">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="4" fill="#003893"/>
            <path transform="translate(4 4)" d="M1 11.7743C1 8.04529 5.92692 5 11.9825 5C18.0731 5 23 8.04529 23 11.7743C23 15.5383 18.0731 18.5829 11.9825 18.5829C5.92692 18.5829 1 15.5383 1 11.7743ZM7.22672 6.50591L9.31446 13.5883L11.5034 5.58183C9.96322 5.65028 8.49153 5.95831 7.22596 6.50591H7.22672ZM11.4014 9.44776L10.2712 13.5883H12.5636L11.3999 9.44776H11.4014ZM14.6513 13.5883L16.6364 6.43746C15.2607 5.90092 13.8011 5.61128 12.3248 5.58183L14.6521 13.5883H14.6513ZM7.19249 13.5883L6.06382 9.44776L4.93438 13.5883H7.19325H7.19249ZM20.2285 14.4097C20.7761 13.6225 21.0841 12.7326 21.0841 11.7743C21.0841 9.96114 19.9547 8.35332 18.1073 7.18966L20.2278 14.4089L20.2285 14.4097ZM17.902 13.5883L16.7383 9.44776L15.6096 13.5883H17.902ZM18.1415 14.4097H15.37L15.1305 15.2995L15.7808 17.42C16.7888 17.1316 17.7382 16.6682 18.5857 16.051L18.1415 14.4089V14.4097ZM8.25423 17.42L7.43283 14.4089H4.69481L4.45599 15.2645C5.4143 16.2228 6.7141 16.9758 8.25347 17.4542V17.42H8.25423ZM12.8039 14.4089H10.0324L9.14257 17.6938C10.0324 17.8991 10.9907 18.0018 11.9825 18.0018C12.5986 18.0018 13.1804 17.9676 13.7622 17.8649L12.8039 14.4089ZM5.68734 7.32656C3.97608 8.45599 2.91585 10.0296 2.91585 11.7743C2.91585 12.7326 3.22388 13.5883 3.73726 14.3754L5.68734 7.32656Z" fill="#F1F5F9"/>
          </svg>
        </ng-container>
        <ng-template #approvedShop>
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="4" fill="#64748B"/>
            <path d="M20.9607 14.0052H17.9948V11.0393L21.455 7.57913C20.3481 7.05048 19.1045 6.878 17.8956 7.08543C16.6866 7.29286 15.5716 7.87 14.7042 8.73737C13.8369 9.60475 13.2597 10.7197 13.0523 11.9287C12.8449 13.1377 13.0174 14.3812 13.546 15.4881L7.61425 21.4199C7.22095 21.8132 7 22.3466 7 22.9028C7 23.459 7.22095 23.9924 7.61425 24.3857C8.00755 24.779 8.54098 25 9.09719 25C9.6534 25 10.1868 24.779 10.5801 24.3857L16.5119 18.454C17.6188 18.9826 18.8623 19.1551 20.0713 18.9477C21.2803 18.7403 22.3953 18.1631 23.2626 17.2958C24.13 16.4284 24.7071 15.3134 24.9146 14.1044C25.122 12.8955 24.9495 11.6519 24.4209 10.545L20.9607 14.0052Z" fill="#F1F5F9"/>
          </svg>
        </ng-template>
      </ion-col>
      <ion-col class="info-column">
        <ion-row>
          <aaa-text class="title">{{getTitle()}}</aaa-text>
        </ion-row>
        <ion-row>
          <aaa-text class="subtitle description" display="flex" align="left" i18n>Member Discount Available</aaa-text>
        </ion-row>
      </ion-col>
      <ion-col class="info-column" size="auto" *ngIf="destination.ratingSummary?.reviewCount > 0 && destination.ratingSummary?.ratingAvg >= 3.5">
        <ion-row class="title ratings">
          <app-rating-v2 [rate]="destination.ratingSummary.ratingAvg"></app-rating-v2>
          <aaa-text i18n>{{ destination.ratingSummary.ratingAvg | number:'1.0-1' }}</aaa-text>
        </ion-row>
        <ion-row class="ion-justify-content-end">
          <app-reviews-count-v2 [numberReviews]="destination.ratingSummary.reviewCount"></app-reviews-count-v2>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-template>
