<ion-grid>
  <ion-row>
    <ion-col>
      <icon-aaa-logo class="logo"></icon-aaa-logo>
    </ion-col>
  </ion-row>
  <ng-container *ngIf="!(isSecure$ | async)">
    <ion-row>
      <ion-col>
        <aaa-text color="dark" align="center" weight="bold"><span style="font-size: 20px;" i18n>Welcome!</span></aaa-text>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <aaa-text color="dark" align="center" size="footnote"><span style="font-size: 13px;" i18n>Please enter your AAA Member details to get started with your roadside request.</span></aaa-text>
      </ion-col>
    </ion-row>
  </ng-container>
  <ion-row class="last-row">
    <ion-col>
      <ng-template #noVendors>
        <app-member-credentials [displayCaptcha]="(isCaptchaRequired$ | async)">
        </app-member-credentials>
      </ng-template>
      <ng-container *ngIf="isWhiteLabel; else noVendors">
        <app-white-label-credentials [mode]="appId">
        </app-white-label-credentials>
      </ng-container>
    </ion-col>
  </ion-row>
</ion-grid>
