import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Store } from '@ngrx/store'
import { AAAStore } from '../../../../../store/root-reducer'
import { LocationUtils } from '../../../../location/location.utils'
import { TaggingService } from '../../../../tagging/tagging.service';
import { AdobeEventService } from '../../../../tagging/adobe/event-adobe.service'
import { ShopDetailsComponent } from '../shop-details/shop-details.component'

@Component({
  selector: 'app-shop-details-modal',
  templateUrl: './shop-details-modal.component.html',
  styleUrls: ['./shop-details-modal.component.scss'],
})
export class ShopDetailsModalComponent extends ShopDetailsComponent {

  @Output() onCloseModal: EventEmitter<void> = new EventEmitter()

  constructor(
    protected store$: Store<AAAStore>,
    protected locationUtils: LocationUtils,
    protected taggingService: TaggingService,
    protected adobeEventService: AdobeEventService
  ) {
    super(store$, locationUtils, taggingService, adobeEventService, null)
  }

  @Input() isOpen = true
  @Input() id: number | string

  handleCloseModal() {
    this.onCloseModal.emit()
  }
}
