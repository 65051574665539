<aaa-modal
  i18n-title
  i18n-subtitle
  [isOpen]="isOpen"
  [initialBreakpoint]="1"
  [breakpoints]="[0, 1]"
  contentMode="fit"
  title="How many people need a ride in the tow truck?"
  (aaaBreakpointDidChange)="close.emit()"
>
  <div class="modal-container">
    <aaa-button
      *ngFor="let option of passengerOptions; let i = index"
      expand="block"
      variant="outline"
      type="button"
      [tabIndex]="500 + i"
      (click)="selectOption(option)"
    >
      <span class="ion-text-capitalize">{{ option.label }}</span>
    </aaa-button>
  </div>
</aaa-modal>
